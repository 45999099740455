<template>
    <div class="container-fluid p-0 m-0">
        <section class="container head">
            <div class="title">{{ $t('parents.parents') }}</div>
        </section>

        <section class="banner-img vh-80 p-0">
            <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/parents-1-1.png" alt="">
        </section>

        <section class="vita-content">
            <div class="content-head">
                <div class="content-title">
                    {{ $t('parents.parents') }}
                </div>
            </div>
            <div class="content p-l-20 p-r-20">
                <div class="line-gray"></div>
                <div class="m-t-20" v-html="$t('parents.parentsNote')"></div>
            </div>
        </section>

        <section class="vita-static-gallery row">
            <div class="left-panel col-4">
                <div class="panel-head">
                    <div class="content-title" style="width: 300px">
                        {{ $t('parents.safeSecure') }}　　　 <br>　
                        {{ $t('parents.designedToFuelSuccess') }}
                    </div>
                </div>
                <ul class="label-items">
                    <li class="label-item" v-for="item of services">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div class="right-panel col-8">
                <picture>
                    <img decoding="async" loading="lazy"
                         src="https://static.vitaalumnicn.com/static/vsweb/parents-2-1.png">
                </picture>
                <picture>
                    <img decoding="async" loading="lazy"
                         src="https://static.vitaalumnicn.com/static/vsweb/parents-2-2.png">
                </picture>
                <picture>
                    <img decoding="async" loading="lazy"
                         src="https://static.vitaalumnicn.com/static/vsweb/parents-2-3.png">
                </picture>
            </div>
        </section>


        <section class="featured-posts" v-show="false">
            <div class="common-header">
                <div class="common-title font-color-black font-size-26" style="width: 190px; line-height: 1.2">
                    {{ $t('parents.storiesYouMight') }}
                </div>
            </div>
            <div class="container-fluid p-0 m-0">
                <div class="imgs row m-t-40" style="max-width: 1180px; margin: 0 auto">
                    <div class="col-4" v-for="story of stories">
                        <div class="panel">
                            <div class="item">
                                <img class="img" :src="story.img" alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title">
                                    {{ story.type }}
                                </div>
                                <div class="footer-sub">
                                    {{ story.title }}
                                </div>
                                <div class="time">
                                    {{ story.time }}
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="vita-content">
            <div class="content-head">
                <div class="content-title">
                    {{ $t('parents.safetySecurity') }}
                </div>
            </div>
            <div class="content p-l-20 p-r-20">
                <div class="line-gray"></div>
                <div class="m-t-20">
                    {{ $t('parents.safetySecurityNote1') }}
                    <br><br>
                    {{ $t('parents.safetySecurityNote2') }}
                </div>
            </div>
        </section>

        <section class="banner-img vh-60">
            <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/parents-4-1.png" alt="">
        </section>

        <section class="faqs">
            <div class="faqs-head">
                <div class="faqs-title">{{ $t('parents.faqs') }}</div>
            </div>
            <div class="accordion-group">
                <div class="accordion-card" :class="index === 0 ? 'border-top' : ''" v-for="(item, index) of faqs">
                    <div class="accordion-header cursor" @click="handleSpan(index)">
                        <div class="accordion-rn">{{ (index + 1)|rnFilter }}</div>
                        <div class="accordion-question">
                            <span class="faq-title">{{ item.title }}</span>
                        </div>
                        <div class="accordion-more">
                            <span v-if="faqSpans[index]">LESS -</span>
                            <span v-else>MORE +</span>
                        </div>
                    </div>
                    <div class="accordion-content" v-if="faqSpans[index]" v-html="item.content"></div>
                    <div class="underline"></div>
                </div>
            </div>
        </section>

        <section class="banner-img vh-80">
            <img class="img" src="https://static.vitaalumnicn.com/static/vsweb/parents-5-1.png" alt="">
        </section>

    </div>
</template>

<script>
export default {
    filters: {
        rnFilter(rn) {
            return ('00' + rn).slice(-2);
        }
    },
    name: "faqs",
    data() {
        return {
            faqSpans: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
        }
    },
    computed: {
        services() {
            return [
                this.$t('parents.allIncurableOfBills'),
                this.$t('parents.fobAccess'),
                this.$t('parents.atAllHours'),
                this.$t('parents.freeContentsInsurance'),
                this.$t('parents.mentalHealthAndWellbeingSupport'),
                this.$t('parents.freeBreakfast'),
                this.$t('parents.superfastWifi'),
                this.$t('parents.spaciousBedrooms'),
                this.$t('parents.secureParcelStorage'),
                this.$t('parents.closeToUniversity'),
                this.$t('parents.disabilityAccess'),
                this.$t('parents.fortnightlyRoomCleans'),
                this.$t('parents.wellLitBuildings'),
                this.$t('parents.privateStudySpaces'),
                this.$t('parents.careerOpportunities')
            ]
        },
        faqs() {
            let faqs = [
                {
                    rn: 1,
                    title: this.$t('parents.faqs1'),
                    content: this.$t('parents.faqsNote1')
                },
                {
                    rn: 2,
                    title: this.$t('parents.faqs2'),
                    content: this.$t('parents.faqsNote2')
                },
                {
                    rn: 3,
                    title: this.$t('parents.faqs3'),
                    content: this.$t('parents.faqsNote3')
                },
                {
                    rn: 4,
                    title: this.$t('parents.faqs4'),
                    content: this.$t('parents.faqsNote4')
                },
                {
                    rn: 5,
                    title: this.$t('parents.faqs5'),
                    content: this.$t('parents.faqsNote5')
                },
                {
                    rn: 6,
                    title: this.$t('parents.faqs6'),
                    content: this.$t('parents.faqsNote6')
                },
                {
                    rn: 7,
                    title: this.$t('parents.faqs7'),
                    content: this.$t('parents.faqsNote7')
                },
                {
                    rn: 8,
                    title: this.$t('parents.faqs8'),
                    content: this.$t('parents.faqsNote8')
                },
                {
                    rn: 9,
                    title: this.$t('parents.faqs9'),
                    content: this.$t('parents.faqsNote9')
                },
                {
                    rn: 10,
                    title: this.$t('parents.faqs10'),
                    content: this.$t('parents.faqsNote10')
                },
                {
                    rn: 11,
                    title: this.$t('parents.faqs11'),
                    content: this.$t('parents.faqsNote11')
                },
                {
                    rn: 12,
                    title: this.$t('parents.faqs12'),
                    content: this.$t('parents.faqsNote12')
                },
                {
                    rn: 13,
                    title: this.$t('parents.faqs13'),
                    content: this.$t('parents.faqsNote13')
                },
                {
                    rn: 14,
                    title: this.$t('parents.faqs14'),
                    content: this.$t('parents.faqsNote14')
                },
                {
                    rn: 15,
                    title: this.$t('parents.faqs15'),
                    content: this.$t('parents.faqsNote15')
                }

            ]
            for (let i = this.faqSpans.length; i < faqs.length; i++) {
                this.faqSpans.push(false);
            }
            return faqs;
        },
        stories() {
            return [
                {
                    type: "Wellbeing",
                    title: "11 tips on how to make friends at uni",
                    link: "",
                    time: "11.04.22 - 09 Mins Read",
                    img: "https://static.vitaalumnicn.com/static/vsweb/parents-3-1.png"
                },
                {
                    type: "NEW",
                    title: "Moving to Vita Student this year? Here’s how to prepare.",
                    link: "",
                    time: " 27.07.23 - 10 Mins Read",
                    img: "https://static.vitaalumnicn.com/static/vsweb/parents-3-2.png"
                },
                {
                    type: "Productivity",
                    title: "How to decorate your university room",
                    link: "",
                    time: "16.08.23 - 05 Mins Read",
                    img: "https://static.vitaalumnicn.com/static/vsweb/parents-3-3.png"
                }
            ]
        }
    },
    methods: {
        handleSpan(index) {
            this.faqSpans[index] = !this.faqSpans[index];
            this.$forceUpdate();
        },
    }
}
</script>

<style lang="less" scoped>

.head {
    padding: 135px 45px 92px;
    background: #fff;
    margin: 0 auto;
    color: #000;

    .title {
        font-size: 80px;
        font-weight: 700;
        font-family: Trade Gothic LT Std Condensed, sans-serif;
    }

    .title:before {
        margin-top: -15px;
        width: 86px;
        content: "";
        display: block;
        background-color: #e91a4c;
        position: absolute;
        height: 7px;
    }
}

.banner-img {
    height: 80vh;
    background-color: #fff;
    padding: 0 45px;

    .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

}

.banner-img-header {
    height: 80vh;
    background-color: #fff;
    padding: 0 45px;

    .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


.featured-posts {
    background-color: #f5f5f5;
    color: #000;
    padding: 45px;

    .imgs {
        margin-top: 20px;

        .panel {
            cursor: pointer;

            .item {
                position: relative;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                font-weight: 900;
                color: #fff;
                aspect-ratio: 1 / 1;
                transition: transform 0.6s ease;

                img {
                    top: 0;
                    object-fit: cover;
                    position: absolute;
                    width: auto;
                    height: 100%;
                    transition: transform 0.5s ease;
                }
            }

            .footer-panel {
                background-color: #fff;
                font-weight: 700;
                position: relative;
                padding: 10px;
                height: 150px;
                color: #000;

                .footer-title {
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                    text-transform: uppercase;
                    color: #a7a7a7;
                    font-size: 16px;
                    line-height: 1.8;
                }

                .footer-sub {
                    font-size: 26px;
                    font-weight: 900;
                    text-transform: uppercase;
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                }

                .footer-sub::after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 50px;
                    background-color: #5b5b5b;
                    height: 1px;
                    top: 5px;
                }

                .icon-btn {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    background-color: #e91a4c;
                    color: white;
                    font-size: 20px;
                    font-weight: bold;
                    z-index: 1;
                    right: 0;
                    bottom: 0;
                }

                .time {
                    position: absolute;
                    font-size: 14px;
                    z-index: 1;
                    left: 10px;
                    bottom: 10px;
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                    color: #a7a7a7;
                }
            }
        }

        .panel:hover {
            .item img {
                transform: scale(1.15);
            }

            .footer-sub {
                color: #e91a4c;
            }

            .footer-sub::after {
                background-color: #e91a4c;
            }
        }

    }

}


</style>
